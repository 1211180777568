import React, { useState, useEffect } from 'react';

import { FaTimes } from 'react-icons/fa';
import { Container, Label, Input, Select, HelperText } from './styles';

interface IChange {
  name: string;
  value: string | string[];
}

interface IOption {
  value: string;
  title: string;
}

interface IProps {
  label: string;
  name: string;
  type?: 'text' | 'hidden' | 'number' | 'date' | 'email' | 'password' | 'file';
  required?: boolean;
  minLength?: number;
  initialValue?: string | string[];
  onChange: (e: IChange) => void;
  normalize?: (e: string) => string;
  onError?: (name: string) => void;
  onBlur?: () => void;
  onReset?: (name: string) => void;
  validation?: (value: string) => boolean;
  select?: boolean;
  options?: IOption[];
  multipleSelect?: boolean;
  disabled?: boolean;
  initialChanges?: boolean;
  autoComplete?: 'on' | 'off';
}

const InputElement: React.FC<IProps> = ({
  label,
  name,
  type = 'text',
  required = false,
  initialValue = '',
  minLength,
  normalize,
  onError,
  onReset,
  onBlur,
  onChange,
  validation,
  select,
  options,
  multipleSelect,
  disabled,
  initialChanges = false,
  autoComplete = 'on',
}) => {
  const [value, setValue] = useState<string | string[]>(initialValue);

  useEffect(() => {
    if (initialValue && initialChanges) {
      setValue(initialValue);
    }
  }, [initialValue, initialChanges]);

  const [error, setError] = useState('');

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    if (multipleSelect) {
      let v: string[] = [];
      if (typeof value === 'object') {
        v = value;
      }
      setValue([...v, e.target.value]);
      onChange({
        name,
        value: [...v, e.target.value],
      });
    } else {
      setValue(normalize ? normalize(e.target.value) : e.target.value);
      onChange({
        name,
        value: normalize ? normalize(e.target.value) : e.target.value,
      });
    }
  }

  function handleValidation() {
    setError('');
    if (onReset) {
      onReset(name);
    }
    if (minLength && value && value.length < minLength) {
      if (onError) {
        onError(name);
      }
      return setError(`Mínimo de ${minLength} caracteres`);
    }
    if (validation && typeof value === 'string' && !validation(value)) {
      if (onError) {
        onError(name);
      }
      return setError('Valor inválido');
    }
    if (value && value.length === 0 && required) {
      if (onError) {
        onError(name);
      }
      return setError('Preencha este campo');
    }
    if (onReset) {
      return onReset(name);
    }
    if (onBlur !== undefined) {
      onBlur();
    }
    return '';
  }

  return (
    <Container
      htmlFor={name}
      style={{ display: type === 'hidden' ? 'none' : 'block' }}
    >
      <Label>
        {label}
        {required && ' *'}
      </Label>
      {select ? (
        <>
          <Select
            value={multipleSelect ? '' : value}
            name={name}
            id={name}
            error={error !== ''}
            onChange={handleChange}
            onBlur={handleValidation}
            required={multipleSelect ? false : required}
            disabled={disabled}
          >
            <option value="" /> {/* eslint-disable-line */}
            {options &&
              options.map(option => {
                if (
                  multipleSelect &&
                  typeof value === 'object' &&
                  value.find(i => i === option.value)
                ) {
                  return '';
                }
                return (
                  <option key={option.value} value={option.value}>
                    {option.title}
                  </option>
                );
              })}
          </Select>
          {multipleSelect &&
            typeof value === 'object' &&
            value.map(i => (
              <div className="selected form-control" key={i}>
                <strong>{options?.find(c => c.value === i)?.title}</strong>
                <button
                  type="button"
                  onClick={() => {
                    setValue(value.filter(c => c !== i));
                    onChange({
                      name,
                      value: value.filter(c => c !== i),
                    });
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
        </>
      ) : (
        <Input
          value={value}
          type={type}
          name={name}
          id={name}
          className="form-control"
          onChange={handleChange}
          onBlur={handleValidation}
          required={required}
          disabled={disabled}
          autoComplete={autoComplete}
        />
      )}

      <HelperText>{error}</HelperText>
    </Container>
  );
};

export default InputElement;
