import React from 'react';

import { IOrder } from '../interface/Order';
import { formatCurrency } from '~/utils/formatting';

interface IProps {
  order: IOrder;
}

const Resume: React.FC<IProps> = ({ order }) => {
  return (
    <div className="price">
      <div>
        <span>
          Subtotal{' '}
          <small style={{ fontSize: '65%' }}>
            ({!order.loading && order.orderProducts?.length} Produto)
          </small>
        </span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.subtotal))}
        </p>
      </div>
      <div>
        <span>Serviços</span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.service))}
        </p>
      </div>
      <div>
        <span>Frete</span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.frete))}
        </p>
      </div>
      <div>
        <span>Desconto</span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.discount))}
        </p>
      </div>
      <div>
        <span>Total</span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.total))}
        </p>
      </div>
    </div>
  );
};

export default Resume;
