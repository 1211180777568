import React from 'react';

const Service: React.FC = () => {
  return (
    <div className="container page__confirm">
      <div className="row">
        <div className="col-12">
          <h3 className="text-center  mb-4">O seguro garantia estendida.</h3>
          <p>
            Em parceria com a ASSURANT, as lojas Império oferece aos
            consumidores o Seguro de Garantia Estendida Original de 12 até 24
            meses, a depender do plano escolhido, contra defeitos funcionais. A
            extensão da garantia começa a valer após o término da garantia do
            fabricante.
          </p>

          <h4 className="text-center mt-4 mb-4">
            Adquira o Seguro Garantia Estendida para o seu produto.
          </h4>
          <p>
            É simples, rápido e fácil! Você pode adquirir a garantia estendida
            comprando em conjuto com um produto que você deseja, dessa forma,
            você terá maior cobertura para possíveis problemas com seus
            produtos.
          </p>

          <p>
            Para garantir o Seguro Garantia Estendida junto com a sua compra
            atual basta escolher o produto que você deseja, e lá estarão
            disponíveis os planos que podem ser contratados para o produto. Após
            a esolha do plano, basta preencher o formulário com os dados
            solicitados e realizar o pagamento, lembrando que o valor do seguro
            contratado será incluso no valor do produto que está sendo comprado.
            Pronto, após o témino da garantia de fábrica do produto, o Seguro
            Garantia Estendida começará a valer imediatamente.
          </p>

          <p>
            A Império Móveis e Eletro S.A. sob CNPJ 27.936.211/0001-97,
            lojasimperio.com.br, sob sede na Av. Agamenon Magalhães, nº 2847, 4º
            Andar, Sala 1, bairro Espinheiro, Recife/PE, CEP 52.020-000 é
            Representante de Seguros da Assurant Seguradora S/A e está
            autorizada a comercializar o Seguro de Garantia Estendida/Extensão
            de Garantia Ramo 0195, Processo SUSEP: 15414.900540/2017-57.
            Processo SUSEP: 15414.900540/2017-57 Restrições se aplicam. Consulte
            as Condições Gerais do Seguro antes de contratá-lo.
          </p>
          <p>
            O registro deste plano na SUSEP não implica, por parte da autarquia,
            incentivo ou recomendação à sua comercialização. Este seguro é
            garantido pela Assurant Seguradora S.A, sob o CNPJ
            03.823.704/0001-52, sob sede na Alameda Rio Negro, 585, 3º andar,
            Bloco C, Alphaville, Barueri-SP, CEP: 06454-000,
            www.assurant.com.br. Inscrição Susep 0214-3 A CONTRATAÇÃO DO SEGURO
            É OPCIONAL SENDO POSSIVEL O CANCELAMENTO DO CONTRATO EM ATÉ 7 (SETE)
            DIAS CORRIDOS COM A DEVOLUÇÃO INTEGRAL DO VALOR PAGO.
          </p>
          <p>
            É proibido condicionar desconto no preço do bem à aquisição do
            seguro.
          </p>
          <p>
            A comercialização de seguro é fiscalizada pela SUSEP:
            www.susep.gov.br - 0800 021 8484
          </p>
          <p>
            - Danos Elétricos. Esta cobertura é complementar à garantia do
            fabricante para danos elétricos não cobertos por ele e sua vigência
            corresponde ao mesmo período de cobertura da garantia de fábrica.
            Cessando assim, imediatamente, com o término da garantia do
            fabricante ou ainda na hipótese de indenização ou troca do produto
            pela Seguradora.
          </p>
          <p>
            - Garantia Estendida Original. Esta cobertura contempla as mesmas
            garantias do fabricante e sua vigência se inicia imediatamente após
            o término da garantia de fábrica.
          </p>
          <p>Consulte o número de 0800 do SAC no Bilhete de Seguro</p>
          <p>
            SAC - Serviço de Atendimento ao Cliente - Horário de atendimento: 24
            horas, 7 dias por semana. Horário de abertura de sinistro: das 08hs
            às 20hs de segunda a sábado.
          </p>
          <p>
            Deficiente Auditivo ou de Fala: 0800 726 6363 - Horário de
            funcionamento: 24 horas, 7 dias por semana.
          </p>
          <p>
            Ouvidoria: 0800 771 7266 - Caso já tenha registrado sua reclamação
            no SAC e não esteja satisfeito -
          </p>
          <p>
            ouvidoria@assurant.com - Horário de atendimento: das 09hs às 18hs de
            segunda a sexta.
          </p>
          <p>DISQUE SUSEP: 0800 021 8484.</p>
        </div>
      </div>
    </div>
  );
};

export default Service;
