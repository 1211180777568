import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

type Props = { component: React.FC; isPrivate?: boolean } & RouteProps;

const RouteWrapper: React.FC<Props> = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props: RouteProps) => <Component {...props} />} />
  );
};

export default RouteWrapper;
