import {
  AuthActionTypes,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SIGN_UP_REQUEST,
} from './types';

export function signInRequest(
  email: string,
  password: string,
): AuthActionTypes {
  return {
    type: SIGN_IN_REQUEST,
    payload: { email, password },
  };
}

export function signInSuccess(token: string): AuthActionTypes {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { token },
  };
}

export function signOut(): AuthActionTypes {
  return {
    type: SIGN_OUT,
  };
}

export function signUpRequest(
  name: string,
  email: string,
  password: string,
): AuthActionTypes {
  return {
    type: SIGN_UP_REQUEST,
    payload: {
      name,
      email,
      password,
    },
  };
}
