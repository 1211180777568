import React from 'react';

// import { Container } from './styles';

export const LoadingScreen: React.FC = () => {
  return (
    <div className="loading__screen">
      <h2>Carregando, aguarde um momento...</h2>
    </div>
  );
};
