import produce from 'immer';

import {
  IInitialState,
  AuthActionTypes,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
} from './types';

const INITIAL_STATE: IInitialState = {
  token: null,
  signedIn: false,
};

export default function auth(
  state = INITIAL_STATE,
  action: AuthActionTypes,
): IInitialState {
  return produce(state, draft => {
    switch (action.type) {
      case SIGN_IN_SUCCESS: {
        draft.token = action.payload.token;
        draft.signedIn = true;
        break;
      }
      case SIGN_OUT: {
        draft.token = null;
        draft.signedIn = false;
        break;
      }
      default:
    }
  });
}
