import React, { useState, useEffect } from 'react';

import { IOrder } from '../interface/Order';
import { IDelivery } from '../interface/Delivery';
import { IHeaders } from '../interface/Headers';
import { formatCurrency } from '~/utils/formatting';
import api from '~/services/api';

interface IProps {
  order: IOrder;
  headersTokens: IHeaders;
  getSubmitedData: (data: IDelivery) => void; // eslint-disable-line
  getSubmitedDelete: () => void; // eslint-disable-line
}

const Delivery: React.FC<IProps> = ({
  order,
  headersTokens,
  getSubmitedData,
  getSubmitedDelete,
}) => {
  const [deliveries, setDeliveries] = useState<IDelivery[]>([]);
  const [delivery, setDelivery] = useState<IDelivery>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setErrorMessage('');
    async function loadDeliveries() {
      try {
        const { data: dataDeliveries } = await api.get(
          `orders/deliveries/${order.id}`,
          headersTokens,
        );
        if (order.orderDelivery) {
          setDelivery({
            store_code: order.orderDelivery.store_code,
            delivery_type: order.orderDelivery.delivery_type,
          });
        }
        setDeliveries(dataDeliveries);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
      setLoading(false);
    }
    loadDeliveries();
  }, []);

  function handleSubmit(value: IDelivery) {
    setDelivery(value);
    return getSubmitedData(value);
  }

  function handleSubmitDelete() {
    setDelivery({
      delivery_type: '',
      store_code: '',
    });
    return getSubmitedDelete();
  }

  return (
    <div>
      {!order.loading && !loading ? (
        <>
          {errorMessage !== '' && (
            <p className="alert alert-danger">{errorMessage}</p>
          )}
          {order.orderDelivery ? (
            <div className="deliveryShow">
              {order.orderDelivery.delivery_type === 'frete' ? (
                <>
                  <strong>Receba em casa</strong>
                  <span>
                    {order.orderDelivery.price === 0
                      ? 'Grátis'
                      : formatCurrency(String(order.orderDelivery.price))}
                  </span>
                  <span>Prazo de {order.orderDelivery.days} dia(s)</span>
                </>
              ) : (
                <>
                  <strong>Retirar na loja</strong>
                  <span>{order.orderDelivery.store?.name}</span>
                  <span>{order.orderDelivery.store?.fullAddress}</span>
                </>
              )}
              <button
                type="button"
                onClick={() => handleSubmitDelete()}
                className="edit"
              >
                Editar
              </button>
            </div>
          ) : (
            <div>
              <div>
                <h4>RECEBER EM CASA</h4>
                <div className="box__deliveryList mb-3">
                  {deliveries
                    .filter(d => d.delivery_type === 'frete')
                    .map(e => {
                      return (
                        <div className="deliveryList" key={e.store_code}>
                          <button
                            type="button"
                            className={
                              e.store_code === delivery?.store_code
                                ? 'active'
                                : ''
                            }
                            onClick={() => handleSubmit(e)}
                          >
                            .
                          </button>
                          <p>
                            <strong>
                              {order.free_shipping
                                ? 'Frete grátis'
                                : 'Valor do frete'}
                            </strong>
                            {!order.free_shipping && (
                              <span>
                                {e.price === 0
                                  ? 'Grátis'
                                  : formatCurrency(String(e.price))}
                              </span>
                            )}
                            {e.days && (
                              <span>
                                Em até{' '}
                                {e.days > 1
                                  ? `${e.days} dias úteis`
                                  : '1 dia útil'}
                              </span>
                            )}
                          </p>
                        </div>
                      );
                    })}
                  {deliveries.filter(d => d.delivery_type === 'frete')
                    .length === 0 &&
                    'Tipo de entrega não disponível para esta compra, escolha a opção "Retirar na Loja"'}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className="textLoading" />
      )}
    </div>
  );
};

export default Delivery;
