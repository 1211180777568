import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

import { REHYDRATE, RehydrateAction } from 'redux-persist';

import history from '~/services/history';

import api from '~/services/api';

import {
  ISignInRequestAction,
  ISignUpRequestAction,
  SIGN_IN_REQUEST,
  SIGN_OUT,
  SIGN_UP_REQUEST,
} from './types';
import { signInSuccess } from './actions';

type UnsafeReturnType<T> = T extends (...args: any[]) => infer R ? R : any; // eslint-disable-line

export function* signIn({ payload }: ISignInRequestAction): any {
  // eslint-disable-line
  try {
    const { email, password } = payload;
    const response = yield call(api.post, 'authentication/user', {
      email,
      password,
    });

    yield put(signInSuccess(response.data.token));
    history.push('/');
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no Login',
        message: 'Verifique seu e-mail ou senha',
        options: {
          showCloseButton: true,
          progressBar: true,
          timeOut: 5000,
        },
      }),
    );
  }
}

export function* signUp({ payload }: ISignUpRequestAction): any {
  // eslint-disable-line
  try {
    const { name, email, password } = payload;
    const response = yield call(api.post, 'users', {
      name,
      email,
      password,
    });

    yield put(signInSuccess(response.data.token));
    history.push('/');
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no Cadastro',
        message: 'Você foi convidado para algum time?',
        options: {
          showCloseButton: true,
          progressBar: true,
          timeOut: 5000,
        },
      }),
    );
  }
}

export function* setToken({ payload }: UnsafeReturnType<RehydrateAction>): any {
  // eslint-disable-line
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    const { data } = yield call(api.get, 'user/me');
  }
}

export function signOut(): any {
  // eslint-disable-line
  window.location.href = '/signin';
}

export default all([
  takeLatest(REHYDRATE, setToken),
  takeLatest(SIGN_IN_REQUEST, signIn),
  takeLatest(SIGN_OUT, signOut),
  takeLatest(SIGN_UP_REQUEST, signUp),
]);
