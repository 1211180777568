import React, { useState, useEffect, useRef } from 'react';

import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { IOrder } from '../interface/Order';
import { IPayment } from '../interface/Payment';
import { ICard } from '../interface/Card';
import { IHeaders } from '../interface/Headers';
import { IFormPayment } from '../interface/FormPayment';
import { formatCurrency } from '~/utils/formatting';
import api from '~/services/api';
import { LoadingScreen } from './LoadingScreen';

interface IProps {
  order: IOrder;
  headersTokens: IHeaders;
}

const Payment: React.FC<IProps> = ({ order, headersTokens }) => {
  const reRef = useRef<any>(null);
  const [formPayment, setFormPayment] = useState<IFormPayment>({
    type: '',
    installments: [{ installment: 0, value: order.total }],
  });
  const [formSelect, setFormSelect] = useState<string>('');
  const [formNewCard, setFormNewCard] = useState<boolean>(false);
  const [cards, setCards] = useState<ICard[]>([]);
  const [card, setCard] = useState<ICard>({
    brand: '',
    card: '',
    id: 0,
  });
  const [installment, setInstallment] = useState<number>(0);
  const [creditCard, setCreditCard] = useState<IPayment>({
    type: 'credit_card',
  });
  const [debitCard, setDebitCard] = useState<IPayment>({
    type: 'debit_card',
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const sessionHiddenInput = document.getElementById(
    'SessionIDHidden',
  ) as HTMLInputElement;

  const sessionId = sessionHiddenInput?.value;

  useEffect(() => {
    async function loadCards() {
      const { data: dataCards } = await api.get(
        `customer/me/cards`,
        headersTokens,
      );
      setCards(dataCards);
    }
    if (!order.mask) {
      loadCards();
    }
  }, [order, headersTokens]);

  const history = useHistory();

  function switchFormPayment(type: string) {
    switch (type) {
      case 'pix':
        return 'PIX';
      case 'boleto':
        return 'Boleto';
      case 'credit_card':
        return 'Cartão de crédito';
      case 'debit_card':
        return 'Cartão de débito';
      default:
        return '-';
    }
  }

  function switchFormPaymentIcon(type: string) {
    switch (type) {
      case 'pix':
        return 'fa-qrcode';
      case 'boleto':
        return 'fa-barcode';
      case 'credit_card':
        return 'fa-credit-card';
      case 'debit_card':
        return 'fa-credit-card';
      default:
        return '';
    }
  }

  async function submitPaymentBoleto() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return false;
    }
    setLoading(true);
    try {
      await api.post(
        `orders/payments/boleto`,
        { order_id: order.id, gResponse },
        headersTokens,
      );
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          id: order.id,
          valor: (order.subtotal + order.service) / 100,
          type: 'boleto',
          email: order.customer?.email,
        },
      });
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    }
    return setLoading(false);
  }

  async function submitPaymentPix() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return false;
    }
    setLoading(true);
    try {
      await api.post(
        `orders/payments/pix`,
        { order_id: order.id, gResponse },
        headersTokens,
      );
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          id: order.id,
          valor: (order.subtotal + order.service) / 100,
          type: 'pix',
          email: order.customer?.email,
        },
      });
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    }
    return setLoading(false);
  }

  async function submitPaymentCardCredit() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return false;
    }
    setLoading(true);
    try {
      await api.post(
        `orders/payments/credit_card`,
        {
          order_id: order.id,
          cardholderName: creditCard.cardHolder,
          number: creditCard.number,
          month: creditCard.month,
          year: creditCard.year,
          cvv: creditCard.cvv,
          installment: creditCard.installment,
          sessionId,
          gResponse,
        },
        headersTokens,
      );
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          id: order.id,
          valor: (order.subtotal + order.service) / 100,
          type: 'cartão',
          email: order.customer?.email,
        },
      });
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    }
    return setLoading(false);
  }

  async function submitPaymentCardCreditAuth() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return false;
    }
    setLoading(true);
    try {
      await api.post(
        `orders/payments/credit_card`,
        {
          order_id: order.id,
          customer_card_id: card.id,
          sessionId,
          installment,
          gResponse,
        },
        headersTokens,
      );
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          id: order.id,
          valor: (order.subtotal + order.service) / 100,
          type: 'cartão',
          email: order.customer?.email,
        },
      });
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    }
    return setLoading(false);
  }

  async function submitPaymentCardDebit() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return false;
    }
    setLoading(true);
    try {
      await api.post(
        `orders/payments/debit_card`,
        {
          order_id: order.id,
          cardholderName: debitCard.cardHolder,
          number: debitCard.number,
          month: debitCard.month,
          year: debitCard.year,
          cvv: debitCard.cvv,
          sessionId,
          gResponse,
        },
        headersTokens,
      );
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          id: order.id,
          valor: (order.subtotal + order.service) / 100,
          type: 'cartão',
          email: order.customer?.email,
        },
      });
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    }
    return setLoading(false);
  }

  async function submitPaymentCardDebitAuth() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return false;
    }
    setLoading(true);
    try {
      await api.post(
        `orders/payments/debit_card`,
        {
          order_id: order.id,
          customer_card_id: card.id,
          sessionId,
          gResponse,
        },
        headersTokens,
      );
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          id: order.id,
          valor: (order.subtotal + order.service) / 100,
          type: 'cartão',
          email: order.customer?.email,
        },
      });
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    }
    return setLoading(false);
  }

  return (
    <div>
      {loading && <LoadingScreen />}
      {!order.loading ? (
        <>
          <div className="content__type_payment flex-row">
            {order.formPayments &&
              order.formPayments.map(e => {
                return (
                  <button
                    className={
                      e.type === formSelect
                        ? `active type_payment mr-4 ${e.type}`
                        : `type_payment mr-4 ${e.type}`
                    }
                    onClick={() => {
                      setErrorMessage('');
                      setFormSelect(e.type);
                      setFormPayment(e);
                    }}
                    type="button"
                    key={e.type}
                  >
                    <i
                      className={`fas ${switchFormPaymentIcon(String(e.type))}`}
                    />
                    <div>
                      <p>{switchFormPayment(String(e.type))}</p>
                      <span />
                    </div>
                  </button>
                );
              })}
          </div>
          {formPayment.type === 'debit_card' && (
            <div className="row">
              <div className="col">
                {order.mask || formNewCard ? (
                  <form>
                    {errorMessage !== '' && (
                      <p
                        className="alert alert-danger mb-3"
                        id="error__message"
                      >
                        {errorMessage}
                      </p>
                    )}
                    <div className="form-group mb-2 col-md-5">
                      <p className="form-label m-0">Número do cartão *</p>
                      <input
                        name="number"
                        placeholder="0000 0000 0000 0000"
                        className="form-control"
                        onChange={e =>
                          setDebitCard({
                            ...debitCard,
                            number: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group mb-2 col-md-4">
                      <p className="form-label m-0">Data de vencimento *</p>
                      <div className="d-flex">
                        <select
                          name="month"
                          onChange={e =>
                            setDebitCard({
                              ...debitCard,
                              month: e.target.value,
                            })
                          }
                          className="mr-1 form-control"
                        >
                          <option value="">MM</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                        <select
                          name="year"
                          onChange={e =>
                            setDebitCard({
                              ...debitCard,
                              year: e.target.value,
                            })
                          }
                          className="ml-1 form-control"
                        >
                          <option value="">AAAA</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                          <option value="2028">2028</option>
                          <option value="2029">2029</option>
                          <option value="2030">2030</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-2 col-md-3">
                      <p className="form-label m-0">CVV *</p>
                      <input
                        name="cvv"
                        placeholder="XXX"
                        className="form-control"
                        onChange={e =>
                          setDebitCard({
                            ...debitCard,
                            cvv: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group mb-2 col-md-6">
                      <p className="form-label m-0">Nome do titular *</p>
                      <input
                        name="cardHolder"
                        placeholder="Digite nome do titular"
                        className="form-control"
                        onChange={e =>
                          setDebitCard({
                            ...debitCard,
                            cardHolder: e.target.value,
                          })
                        }
                      />
                      <small className="d-block" style={{ fontSize: '12px' }}>
                        Digite exatamente como está no seu cartão
                      </small>
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        disabled={loading}
                        onClick={() => submitPaymentCardDebit()}
                        className="btn btn-buy"
                      >
                        Finalizar Pedido
                      </button>
                    </div>
                  </form>
                ) : (
                  <div>
                    {errorMessage !== '' && (
                      <p className="alert alert-danger mb-3">{errorMessage}</p>
                    )}
                    {cards.map(e => {
                      return (
                        <div className="addressList" key={e.id}>
                          <button
                            type="button"
                            className={e.id === card.id ? 'active' : ''}
                            onClick={() => setCard(e)}
                          >
                            .
                          </button>
                          <p>{e.card}</p>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      className="btn btn-imperio btn-small"
                      onClick={() => setFormNewCard(true)}
                    >
                      Cadastrar novo cartão de débito
                    </button>
                    <button
                      type="button"
                      disabled={loading}
                      onClick={() => submitPaymentCardDebitAuth()}
                      className="btn btn-buy"
                    >
                      Finalizar Pedido
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {formPayment.type === 'credit_card' && (
            <div className="row">
              <div className="col">
                {order.mask || formNewCard ? (
                  <form>
                    {errorMessage !== '' && (
                      <p
                        className="alert alert-danger mb-3"
                        id="error__message"
                      >
                        {errorMessage}
                      </p>
                    )}
                    <div className="form-group mb-2 col-md-5">
                      <p className="form-label m-0">Número do cartão *</p>
                      <input
                        name="number"
                        placeholder="0000 0000 0000 0000"
                        className="form-control"
                        onChange={e =>
                          setCreditCard({
                            ...creditCard,
                            number: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group mb-2 col-md-4">
                      <p className="form-label m-0">Data de vencimento *</p>
                      <div className="d-flex">
                        <select
                          name="month"
                          onChange={e =>
                            setCreditCard({
                              ...creditCard,
                              month: e.target.value,
                            })
                          }
                          className="mr-1 form-control"
                        >
                          <option value="">MM</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                        <select
                          name="year"
                          onChange={e =>
                            setCreditCard({
                              ...creditCard,
                              year: e.target.value,
                            })
                          }
                          className="ml-1 form-control"
                        >
                          <option value="">AAAA</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                          <option value="2024">2024</option>
                          <option value="2025">2025</option>
                          <option value="2026">2026</option>
                          <option value="2027">2027</option>
                          <option value="2028">2028</option>
                          <option value="2029">2029</option>
                          <option value="2030">2030</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group mb-2 col-md-3">
                      <p className="form-label m-0">CVV *</p>
                      <input
                        name="cvv"
                        placeholder="XXX"
                        className="form-control"
                        onChange={e =>
                          setCreditCard({
                            ...creditCard,
                            cvv: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group mb-2 col-md-6">
                      <p className="form-label m-0">Nome do titular *</p>
                      <input
                        name="cardHolder"
                        placeholder="Digite nome do titular"
                        className="form-control"
                        onChange={e =>
                          setCreditCard({
                            ...creditCard,
                            cardHolder: e.target.value,
                          })
                        }
                      />
                      <small className="d-block" style={{ fontSize: '12px' }}>
                        Digite exatamente como está no seu cartão
                      </small>
                    </div>
                    <div className="form-group mb-2 col-md-6">
                      <p className="form-label m-0">Parcelamento *</p>
                      <select
                        name="installment"
                        id="exampleForm.SelectParcelamento"
                        className="form-control"
                        onChange={e =>
                          setCreditCard({
                            ...creditCard,
                            installment: e.target.value,
                          })
                        }
                      >
                        <option value="">Selecione o parcelamento</option>
                        {formPayment.installments.map(e => {
                          return (
                            <option key={e.installment} value={e.installment}>
                              {e.installment}x de{' '}
                              {formatCurrency(String(e.value))} (sem juros)
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="text-right">
                      <button
                        type="button"
                        disabled={loading}
                        onClick={() => submitPaymentCardCredit()}
                        className="btn btn-buy"
                      >
                        Finalizar Pedido
                      </button>
                    </div>
                  </form>
                ) : (
                  <div>
                    {errorMessage !== '' && (
                      <p className="alert alert-danger mb-3">{errorMessage}</p>
                    )}
                    {cards.map(e => {
                      return (
                        <div className="addressList" key={e.id}>
                          <button
                            type="button"
                            className={e.id === card.id ? 'active' : ''}
                            onClick={() => setCard(e)}
                          >
                            .
                          </button>
                          <p>{e.card}</p>
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      className="btn btn-imperio btn-small"
                      onClick={() => setFormNewCard(true)}
                    >
                      Cadastrar novo cartão de crédito
                    </button>
                    <div className="form-group mb-2 col-md-6 mt-4">
                      <p className="form-label m-0">PARCELAMENTO*</p>
                      <select
                        name="installment"
                        id="exampleForm.SelectParcelamento"
                        className="form-control"
                        onChange={e => setInstallment(Number(e.target.value))}
                      >
                        <option value="">Selecione o parcelamento</option>
                        {formPayment.installments.map(e => {
                          return (
                            <option key={e.installment} value={e.installment}>
                              {e.installment}x de{' '}
                              {formatCurrency(String(e.value))} (sem juros)
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <button
                      type="button"
                      disabled={loading}
                      onClick={() => submitPaymentCardCreditAuth()}
                      className="btn btn-buy"
                    >
                      Finalizar Pedido
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {formPayment.type === 'boleto' && (
            <div className="mb-5 row">
              <div className="col">
                {errorMessage !== '' && (
                  <p className="alert alert-danger mb-3">{errorMessage}</p>
                )}
                <button
                  type="button"
                  onClick={() => submitPaymentBoleto()}
                  className="btn btn-imperio"
                  disabled={loading}
                >
                  Gerar Boleto
                </button>
              </div>
            </div>
          )}
          {formPayment.type === 'pix' && (
            <div className="mb-5 row">
              <div className="col">
                {errorMessage !== '' && (
                  <p className="alert alert-danger mb-3">{errorMessage}</p>
                )}
                <button
                  type="button"
                  onClick={() => submitPaymentPix()}
                  className="btn btn-imperio"
                  disabled={loading}
                >
                  Pagar com PIX
                </button>
              </div>
            </div>
          )}
          <ReCAPTCHA
            sitekey="6Lf8NtUaAAAAAEJyuqWiJAo9K4-y_2KqBfP6A_VM"
            size="invisible"
            ref={reRef}
          />
        </>
      ) : (
        <p className="textLoading" />
      )}
    </div>
  );
};

export default Payment;
