export const SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS';
export const SIGN_OUT = '@auth/SIGN_OUT';
export const SIGN_UP_REQUEST = '@auth/SIGN_UP_REQUEST';

export interface IInitialState {
  token: null | string;
  signedIn: boolean;
}

export interface ISignInRequestAction {
  type: typeof SIGN_IN_REQUEST;
  payload: {
    email: string;
    password: string;
  };
}
export interface ISignInSuccessAction {
  type: typeof SIGN_IN_SUCCESS;
  payload: {
    token: string;
  };
}
export interface ISignOutAction {
  type: typeof SIGN_OUT;
}
export interface ISignUpRequestAction {
  type: typeof SIGN_UP_REQUEST;
  payload: {
    name: string;
    email: string;
    password: string;
  };
}

export type AuthActionTypes =
  | ISignInRequestAction
  | ISignInSuccessAction
  | ISignOutAction
  | ISignUpRequestAction;
