import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { IOrder } from '../interface/Order';
import { IHeaders } from '../interface/Headers';
import api from '~/services/api';

interface IProps {
  order: IOrder;
  headersTokens: IHeaders;
  getSubmitedData: () => void; // eslint-disable-line
}

const Coupon: React.FC<IProps> = ({
  order,
  headersTokens,
  getSubmitedData,
}) => {
  const [couponDiv, setCouponDiv] = useState(false);
  const [code, setCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setCode(order.code_coupon || '');
  }, [order]);

  function submitCoupon() {
    return getSubmitedData();
  }

  async function handleSubmitCoupon() {
    setErrorMessage('');
    try {
      await api.post(
        `orders/coupons`,
        {
          order_id: order.id,
          code,
        },
        headersTokens,
      );
      submitCoupon();
    } catch (e) {
      submitCoupon();
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
    }
  }

  return (
    <div
      className={couponDiv || code !== '' ? 'dealercode active' : 'dealercode'}
    >
      <div>
        <div>
          <p>Cupom de desconto</p>
        </div>
        <button
          type="button"
          onClick={() => {
            setCouponDiv(!couponDiv);
          }}
        >
          {couponDiv ? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </button>
      </div>
      <div className="d-flex box__dealercode">
        {errorMessage !== '' && (
          <p className="alert alert-danger mb-3">{errorMessage}</p>
        )}
        <form>
          <Form.Control
            name="code"
            onChange={e => {
              setCode(String(e.target.value));
            }}
            value={code}
          />
          <button
            type="button"
            onClick={() => handleSubmitCoupon()}
            className="btn btn-imperio pb-2 pl-4 pr-4 pt-2"
          >
            Aplicar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Coupon;
