import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import 'dotenv/config';

TagManager.initialize({
  gtmId: 'GTM-NBLDLWC',
  dataLayerName: 'PageDataLayer',
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
