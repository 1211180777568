import React, { useState } from 'react';

import { FaQuestionCircle } from 'react-icons/fa';
import { IOrder } from '../interface/Order';
import { formatCurrency } from '~/utils/formatting';
import { normalizeCurrency } from '~/utils/normalize';
import { ModalWarrantyExtended } from './ModalWarrantyExtended';

interface IProps {
  order: IOrder;
  getSubmitedAddService: (service_id: number) => void; // eslint-disable-line
  getSubmitedRemoveService: (product_sku:string) => void; // eslint-disable-line
}

const Products: React.FC<IProps> = ({
  order,
  getSubmitedAddService,
  getSubmitedRemoveService,
}) => {
  const [openModal, setOpenModal] = useState(false);
  function handleSubmitAddService(service_id: number) {
    return getSubmitedAddService(service_id);
  }

  function handleSubmitRemoveService(product_sku: string) {
    return getSubmitedRemoveService(product_sku);
  }

  return (
    <div className="products">
      <ModalWarrantyExtended
        open={openModal}
        setOpen={() => setOpenModal(false)}
      />
      <div className="product">
        <table>
          <thead>
            <tr>
              <th>Nome do produto</th>
              <th>Preço</th>
              <th>Qtd.</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {!order.loading ? (
              order.orderProducts?.map(e => {
                return (
                  <tr key={e.sku}>
                    <td>
                      <p>{e.product.name}</p>
                      <p>
                        Vendido por:{' '}
                        <span className="ml-1">
                          {e.product.marketplace.name}
                        </span>
                      </p>
                      {e.product.services && (
                        <div className="d-flex flex-column form-group mt-1">
                          <div className="garantia-estendida form-check">
                            <input
                              name={`garantia_${e.sku}`}
                              type="radio"
                              className="form-check-input"
                              checked={
                                order.orderServices &&
                                order.orderServices.filter(
                                  s => s.product_sku === e.sku,
                                ).length === 0
                              }
                              onClick={() => handleSubmitRemoveService(e.sku)}
                            />
                            <p className="form-check-label">
                              Sem Garantia Estendida
                            </p>
                          </div>
                          {e.product.services.map(ele => {
                            return (
                              <div
                                key={ele.id}
                                className="garantia-estendida security form-check"
                              >
                                <img src="/assets/img/security3.png" alt="" />
                                <input
                                  name={`garantia_${e.sku}`}
                                  type="radio"
                                  className="form-check-input"
                                  checked={
                                    order.orderServices &&
                                    order.orderServices.filter(
                                      s => s.service_sku === ele.service_sku,
                                    ).length > 0
                                  }
                                  onClick={() => handleSubmitAddService(ele.id)}
                                />
                                <p className="form-check-label">
                                  {ele.name} - R${' '}
                                  {normalizeCurrency(String(ele.price))}{' '}
                                  <button
                                    type="button"
                                    className="ml-2"
                                    onClick={() => setOpenModal(true)}
                                  >
                                    <FaQuestionCircle className="text-primary" />
                                  </button>
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </td>
                    <td>
                      <p>{formatCurrency(String(e.price))}</p>
                    </td>
                    <td>
                      <p>{e.qtd}</p>
                    </td>
                    <td>
                      <p>{formatCurrency(String(e.price * e.qtd))}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>
                  <span className="textLoading" />
                </td>
                <td>
                  <span className="textLoading" />
                </td>
                <td>
                  <span className="textLoading" />
                </td>
                <td>
                  <span className="textLoading" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Products;
