import React, { useState, useEffect, useRef } from 'react';

import { Modal } from 'react-bootstrap';
import api from '~/services/api';
import Products from './components/Products';
import Authentication from './components/Authentication';
import Customer from './components/Customer';
import Address from './components/Address';
import Delivery from './components/Delivery';
import PickUpStore from './components/PickUpStore';
import Payment from './components/Payment';
import Resume from './components/Resume';
import Coupon from './components/Coupon';
import Seller from './components/Seller';

import { IOrder } from './interface/Order';
import { IHeaders } from './interface/Headers';
import { IDelivery } from './interface/Delivery';
import { IProps } from './interface/Props';

import { ResumeContainer } from './styles';

interface IError {
  type: string;
  status: number;
  alert: string;
  message: string;
}

const Checkout: React.FC<IProps> = ({ match }) => {
  const [promocionalModal, setPromocionalModal] = useState(false);
  const [order, setOrder] = useState<IOrder>({
    id: 0,
    seller_id: 0,
    order_status_id: 0,
    code_coupon: '',
    free_shipping: false,
    service: 0,
    subtotal: 0,
    discount: 0,
    frete: 0,
    total: 0,
    loading: true,
    mask: true,
  });
  const [error, setError] = useState<IError>({
    type: '',
    status: 0,
    alert: '',
    message: '',
  });
  const [productsStock, setProductsStock] = useState<string[]>([]);
  const [typeDelivery, setTypeDelivery] = useState<string>('');
  const [modalAutentication, setModalAutentication] = useState<boolean>(false);

  const { order_id } = match.params;

  const headersTokens = (): IHeaders => {
    const token = localStorage.getItem('imperio@token');
    const tokenBuyer = localStorage.getItem('imperio@tokenBuyer');
    if (token) {
      return {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
    }
    if (tokenBuyer) {
      return {
        headers: {
          tokenBuyer,
        },
      };
    }
    return {
      headers: {},
    };
  };

  async function getOrder() {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      const { data: dataOrder } = await api.get(
        `/orders/${localStorage.getItem('imperio@order_id')}`,
        headers,
      );
      if (dataOrder.orderDelivery?.delivery_type) {
        setTypeDelivery(dataOrder.orderDelivery.delivery_type);
      }
      setOrder({ ...dataOrder, loading: false });
    } catch (e) {
      // console.log(e);
    }
  }

  const itemRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (itemRef && itemRef.current) {
        const c: any = itemRef.current; // eslint-disable-line
        c.style.transform = `translateY(${
          window.pageYOffset > 110 ? window.pageYOffset - 110 : 0
        }px)`;
      }
    });
  }, []);

  useEffect(() => {
    async function getQuote() {
      try {
        if (order_id) {
          const headers: IHeaders = headersTokens();
          const { data: dataOrder } = await api.get(
            `/orders/${order_id}`,
            headers,
          );
          localStorage.setItem('imperio@order_id', order_id);
          if (dataOrder.orderDelivery?.delivery_type) {
            setTypeDelivery(dataOrder.orderDelivery.delivery_type);
          }
          setOrder({ ...dataOrder, loading: false });
          if (dataOrder.total >= 10000 && dataOrder.promotion_birth === null) {
            setPromocionalModal(true);
          }
        }
      } catch (e) {
        // window.location.href = 'https://www.lojasimperio.com.br/';
      }
    }

    if (order_id !== localStorage.getItem('imperio@order_id')) {
      localStorage.removeItem('imperio@order_id');
      localStorage.removeItem('imperio@tokenBuyer');
      localStorage.removeItem('imperio@token');
    }

    getQuote();
  }, [order_id]); // eslint-disable-line

  async function orderPromotion(choice: boolean) {
    setPromocionalModal(false);
    setOrder({
      ...order,
      loading: true,
    });
    try {
      await api.put(`/orders/promotion/${order.id}`, {
        choice,
      });
    } catch (e) {
      if (e.response.data.length) {
        setError({
          type: 'customer',
          status: e.response.status,
          alert: 'danger',
          message: e.response.data[0].message,
        });
      } else {
        setError({
          type: 'customer',
          status: e.response.status,
          alert: 'danger',
          message: e.response.data.message,
        });
      }
      setPromocionalModal(true);
    }
    setOrder({
      ...order,
      loading: false,
    });
  }

  async function submitUpdateOrder(email: string) {
    try {
      const { data: dataToken } = await api.post(`/auth/buyer/email`, {
        email,
      });
      localStorage.setItem('imperio@tokenBuyer', dataToken.token);

      await api.put(
        `/orders/${localStorage.getItem('imperio@order_id')}`,
        {},
        {
          headers: {
            tokenBuyer: dataToken.token,
          },
        },
      );
      await getOrder();
    } catch (e) {
      if (e.response.data.length) {
        setError({
          type: 'customer',
          status: e.response.status,
          alert: 'danger',
          message: e.response.data[0].message,
        });
      } else {
        setError({
          type: 'customer',
          status: e.response.status,
          alert: 'danger',
          message: e.response.data.message,
        });
      }
    }
  }

  function handleModalAutentication(modal: boolean) {
    setModalAutentication(modal);
  }

  async function submitAuthenticationCustomer(token: string) {
    localStorage.setItem('imperio@token', token);
    setModalAutentication(false);
    await getOrder();
  }

  async function submitLogoutCustomer() {
    try {
      setError({
        type: '',
        status: 0,
        alert: '',
        message: '',
      });

      const headers: IHeaders = headersTokens();

      await api.put(
        `/orders/logout/${localStorage.getItem('imperio@order_id')}`,
        {},
        headers,
      );

      localStorage.removeItem('imperio@tokenBuyer');
      localStorage.removeItem('imperio@token');

      await getOrder();
    } catch (e) {
      console.log(e); // eslint-disable-line
    }
  }

  async function submitedAddService(service_id: number) {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      const findService_id = order.orderServices?.filter(
        s => s.service_id === service_id,
      );

      if (findService_id?.length === 0) {
        await api.post(
          `orders/services`,
          {
            service_id,
            order_id: localStorage.getItem('imperio@order_id'),
          },
          headers,
        );
      } else {
        // await api.delete(`orders/services/${order.id}`, headers);
      }
      await getOrder();
    } catch (e) {
      // console.log(e);
    }
  }

  async function submitedRemoveService(product_sku: string) {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      await api.delete(`orders/services/${order.id}/${product_sku}`, headers);

      await getOrder();
    } catch (e) {
      // console.log(e);
    }
  }

  async function submitAddressDelivery(address_id: number) {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      await api.post(
        `orders/address/delivery`,
        {
          customer_address_id: address_id,
          order_id: localStorage.getItem('imperio@order_id'),
        },
        headers,
      );
      await getOrder();
    } catch (e) {
      // console.log(e);
    }
  }

  async function submitDeliveryOrderDelete() {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      await api.delete(
        `orders/deliveries/${localStorage.getItem('imperio@order_id')}`,
        headers,
      );
      await getOrder();
    } catch (e) {
      // console.log(e);
    }
  }

  async function submitDeliveryOrder(data: IDelivery) {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      await api.post(
        `orders/deliveries`,
        {
          order_id: localStorage.getItem('imperio@order_id'),
          store_code: data.store_code,
          delivery_type: data.delivery_type,
        },
        headers,
      );
      await getOrder();
    } catch (e) {
      // console.log(e);
    }
  }

  return (
    <div className="container page__checkout">
      <Modal className="modal-no-top" show={promocionalModal} centered>
        <Modal.Body>
          <div className="p-3">
            <div
              className="d-flex justify-content-center"
              style={{ marginBottom: '1rem' }}
            >
              <img src="/assets/img/logo.png" alt="Lojas Império" />
            </div>
            <h5 className="text-center">
              Você quer participar da promoção de aniversário das Lojas Império?
            </h5>
            <small className="d-block text-center">
              Quer saber mais?{' '}
              <a
                href="https://aniversario.lojasimperio.com.br:2021/"
                target="blank"
              >
                Clique Aqui!
              </a>{' '}
              Participe!
            </small>
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: '1rem' }}
            >
              <button
                type="button"
                className="btn btn-orange"
                onClick={() => orderPromotion(true)}
              >
                Sim
              </button>
              <button
                type="button"
                className="btn btn-back"
                style={{ marginLeft: '1rem' }}
                onClick={() => orderPromotion(false)}
              >
                Não
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="align-items-center d-flex flex-wrap justify-content-between mt-3 mb-3">
            <h3>ITENS DO PEDIDO</h3>
            <a
              href="https://homolog.lojasimperio.tupi.io/carrinho"
              className="btn btn-imperio btn-carrinho"
            >
              Voltar para o carrinho
            </a>
          </div>
          <div className="painel-product mb-3">
            {productsStock.length > 0 && (
              <p className="alert alert-warning">
                <strong>Produtos removidos por falta de estoque:</strong>
                <br />
                {productsStock.map(e => {
                  return (
                    <>
                      {e}
                      <br />
                    </>
                  );
                })}
              </p>
            )}
            <Products
              order={order}
              getSubmitedAddService={(service_id: number) =>
                submitedAddService(service_id)
              }
              getSubmitedRemoveService={(product_sku: string) =>
                submitedRemoveService(product_sku)
              }
            />
          </div>
          {modalAutentication && (
            <Authentication
              cancelModalAutentication={() => {
                setModalAutentication(false);
              }}
              getSubmitedDataAuthentication={(token: string) =>
                submitAuthenticationCustomer(token)
              }
            />
          )}
          <Customer
            order={order}
            error={error}
            headersTokens={headersTokens()}
            activeModalAutentication={(modal: boolean) =>
              handleModalAutentication(modal)
            }
            getSubmitedData={(email: string) => submitUpdateOrder(email)}
            getSubmitedDataLogout={() => submitLogoutCustomer()}
            getSubmitedDataRegister={() => getOrder()}
          />
          {order.customer && (
            <>
              <h3>SELECIONE SEU ENDEREÇO</h3>
              <div className="mb-4">
                <Address
                  order={order}
                  headersTokens={headersTokens()}
                  getSubmitedData={(address_id: number) =>
                    submitAddressDelivery(address_id)
                  }
                  activeModalAutentication={(modal: boolean) =>
                    handleModalAutentication(modal)
                  }
                />
              </div>
              {order.orderDeliveryAddress && !order.loading && (
                <>
                  <h3>ESCOLHA O TIPO DE ENTREGA</h3>
                  <p>Analise e escolha a melhor opção para você.</p>
                  <div className="mb-4">
                    <div className="mb-4">
                      <button
                        onClick={() => {
                          if (order.orderDelivery) {
                            submitDeliveryOrderDelete();
                          }
                          setTypeDelivery('frete');
                        }}
                        className={`${
                          typeDelivery === 'frete' ? 'active' : ''
                        } btn btn-imperio mr-4`}
                        type="button"
                      >
                        Receber em casa
                      </button>
                      <button
                        onClick={() => {
                          if (order.orderDelivery) {
                            submitDeliveryOrderDelete();
                          }
                          setTypeDelivery('cliqueretire');
                        }}
                        className={`${
                          typeDelivery === 'cliqueretire' ? 'active' : ''
                        } btn btn-imperio`}
                        type="button"
                      >
                        Retirar na loja
                      </button>
                    </div>

                    {typeDelivery === 'frete' && (
                      <Delivery
                        order={order}
                        headersTokens={headersTokens()}
                        getSubmitedData={(data: IDelivery) =>
                          submitDeliveryOrder(data)
                        }
                        getSubmitedDelete={() => {
                          setTypeDelivery('');
                          submitDeliveryOrderDelete();
                        }}
                      />
                    )}
                    {typeDelivery === 'cliqueretire' && (
                      <PickUpStore
                        order={order}
                        headersTokens={headersTokens()}
                        getSubmitedData={(data: IDelivery) =>
                          submitDeliveryOrder(data)
                        }
                        getSubmitedDelete={() => {
                          setTypeDelivery('');
                          submitDeliveryOrderDelete();
                        }}
                      />
                    )}
                  </div>
                </>
              )}
              {order.formPayments && !order.loading && (
                <>
                  <h3>PAGAMENTO</h3>
                  <div className="mb-4">
                    <Payment order={order} headersTokens={headersTokens()} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="col-12 col-md-4">
          <ResumeContainer
            ref={itemRef}
            className="resumeOrder"
            id="resumeOrder"
          >
            <h3>Resumo do pedido</h3>
            <div className="painel mb-3">
              <Resume order={order} />
            </div>
            <div className="painel mb-3">
              <Coupon
                order={order}
                headersTokens={headersTokens()}
                getSubmitedData={() => getOrder()}
              />
            </div>
            <div className="painel mb-3">
              <Seller
                order={order}
                headersTokens={headersTokens()}
                getSubmitedData={() => getOrder()}
              />
            </div>
          </ResumeContainer>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
