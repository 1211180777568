import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-xs-12">
            <small className="copyright">
              <span>
                Copyright © Império Móveis e Eletro - Todos os direitos
                reservados.
              </span>
            </small>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12">
            <div className="payment-method">
              <div style={{ flexWrap: 'wrap' }}>
                <img
                  src="https://www.lojasimperio.com.br/pub/media/pag_card_1.png"
                  alt=""
                  width="44"
                  height="30"
                />
                <img
                  src="https://www.lojasimperio.com.br/pub/media/pag_card_2.png"
                  alt=""
                  width="45"
                  height="31"
                />
                <img
                  src="https://www.lojasimperio.com.br/pub/media/pag_card_3.png"
                  alt=" 3"
                  width="45"
                  height="29"
                />
                <img
                  src="https://www.lojasimperio.com.br/pub/media/pag_card_4.png"
                  alt=""
                  width="45"
                  height="31"
                />
                <img
                  src="https://www.lojasimperio.com.br/pub/media/pag_card_5.png"
                  alt=""
                  width="46"
                  height="20"
                />
                <img
                  src="https://www.lojasimperio.com.br/pub/media/pag_card_6.png"
                  alt=""
                  width="44"
                  height="30"
                />
                <img
                  src="https://www.lojasimperio.com.br/pub/media/pag_card_7.png"
                  alt=""
                  width="45"
                  height="31"
                />
                <img
                  src="https://www.lojasimperio.com.br/pub/media/pag_card_8.png"
                  alt=""
                  width="45"
                  height="34"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
