import React, { useState, useEffect } from 'react';

import QRCode from 'qrcode.react';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';

import { IOrder } from './interface/Order';
import { IHeaders } from './interface/Headers';
import { IProps } from './interface/Props';
import { normalizeCurrency } from '~/utils/normalize';

const Checkout: React.FC<IProps> = ({ match }) => {
  const [linkBoleto, setLinkBoleto] = useState<string>('');
  const [pixPayments, setPixPayments] = useState<any[]>([]);
  const [order, setOrder] = useState<IOrder>({
    id: 0,
    seller_id: 0,
    order_status_id: 0,
    code_coupon: '',
    service: 0,
    subtotal: 0,
    discount: 0,
    frete: 0,
    total: 0,
    loading: true,
    mask: true,
  });
  const { id } = match.params;

  const [isImperioPaid, setIsImperioPaid] = useState(false);
  const [isPromoterPaid, setIsPromoterPaid] = useState(false);

  const headersTokens = (): IHeaders => {
    const token = localStorage.getItem('imperio@token');
    const tokenBuyer = localStorage.getItem('imperio@tokenBuyer');

    if (token) {
      return {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
    }
    if (tokenBuyer) {
      return {
        headers: {
          tokenBuyer,
        },
      };
    }
    return {
      headers: {},
    };
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    async function poolApi() {
      if (order) {
        if (order.form_payment === 'pix') {
          if (order.orderPayment) {
            if (order.orderPayment.length > 1) {
              while (!isImperioPaid) {
                const headers: IHeaders = headersTokens();
                const { data: dataOrder } = await api.get(
                  `/orders/${id}`,
                  headers,
                );

                for (let i = 0; i < dataOrder.orderPayment.length; i += 1) {
                  const payment = dataOrder.orderPayment[i];

                  if (payment.orderPaymentPix) {
                    if (
                      !payment.orderPaymentPix.is_promoter &&
                      payment.orderPaymentPix.status === 'CONCLUIDA'
                    ) {
                      setIsImperioPaid(true);
                      return;
                    }

                    if (
                      !payment.orderPaymentPix.is_promoter &&
                      payment.orderPaymentPix.status === 'EXPIRADA'
                    ) {
                      return;
                    }
                  }
                }

                await delay(10000);
              }
            }
          }
        }
      }
    }

    if (order) {
      poolApi();
    }
  }, [order, isImperioPaid, id]);

  useEffect(() => {
    async function poolApi() {
      if (order) {
        if (order.form_payment === 'pix') {
          if (order.orderPayment) {
            if (order.orderPayment.length > 1) {
              if (isImperioPaid) {
                while (!isPromoterPaid) {
                  const headers: IHeaders = headersTokens();
                  const { data: dataOrder } = await api.get(
                    `/orders/${id}`,
                    headers,
                  );

                  for (let i = 0; i < dataOrder.orderPayment.length; i += 1) {
                    const payment = dataOrder.orderPayment[i];

                    if (payment.orderPaymentPix) {
                      if (
                        payment.orderPaymentPix.is_promoter &&
                        payment.orderPaymentPix.status === 'CONCLUIDA'
                      ) {
                        setIsPromoterPaid(true);
                        return;
                      }
                    }

                    if (
                      payment.orderPaymentPix.is_promoter &&
                      payment.orderPaymentPix.status === 'EXPIRADA'
                    ) {
                      return;
                    }
                  }

                  await delay(10000);
                }
              }
            }
          }
        }
      }
    }

    if (order) {
      poolApi();
    }
  }, [order, isImperioPaid, isPromoterPaid, id]);

  async function getOrder() {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      const { data: dataOrder } = await api.get(`/orders/${id}`, headers);
      setOrder({ ...dataOrder, loading: false });

      if (dataOrder.form_payment === 'boleto') {
        let responseBoleto =
          dataOrder.orderPayment[0]?.orderPaymentBoleto?.response;
        if (responseBoleto && !dataOrder.mask) {
          responseBoleto = JSON.parse(responseBoleto);
          setLinkBoleto(responseBoleto.formats.pdf);
        }
      }

      if (dataOrder.form_payment === 'pix') {
        for (let i = 0; i < dataOrder.orderPayment.length; i += 1) {
          const payment = dataOrder.orderPayment[i];

          if (payment.orderPaymentPix) {
            setPixPayments(prev => [...prev, payment.orderPaymentPix]);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getOrder();
    localStorage.removeItem('imperio@order_id');
    localStorage.removeItem('imperio@tokenBuyer');
    localStorage.removeItem('imperio@token');
  }, []); // eslint-disable-line

  function handlePixFlow(pix: any): JSX.Element {
    if (!pix.is_promoter && isImperioPaid && isPromoterPaid) {
      return <></>;
    }
    if (pix.is_promoter && isImperioPaid && isPromoterPaid) {
      return (
        <div>
          <p>Todos os pagamentos realizados! Obrigado pela preferência.</p>
          <p>
            Para acompanhar o seu pedido, clique{' '}
            <a href="https://homolog.lojasimperio.tupi.io/meus-pedidos">
              Meus pedidos
            </a>
            <br />e consulte o status, através do seu CPF.
          </p>
        </div>
      );
    }
    if (pix.is_promoter && !isImperioPaid && !isPromoterPaid) {
      return <strong>Aguardando o pagamento da primeira transação...</strong>;
    }
    if (pix.is_promoter && isImperioPaid && !isPromoterPaid) {
      return (
        <>
          <QRCode value={pix.qr_code_text} size={200} />

          <strong className="mt-2">
            Valor: R$ {normalizeCurrency(String(pix.value) || '000')}
          </strong>

          <button
            type="button"
            className="mt-2 btn btn-imperio"
            onClick={() => {
              navigator.clipboard.writeText(pix.qr_code_text);
              toastr.info(' ', 'Código PIX Copiado');
            }}
          >
            COPIAR CÓDIGO PIX
          </button>
        </>
      );
    }
    if (!pix.is_promoter && !isImperioPaid) {
      return (
        <>
          <QRCode value={pix.qr_code_text} size={200} />

          <strong className="mt-2">
            Valor: R$ {normalizeCurrency(String(pix.value) || '000')}
          </strong>

          <button
            type="button"
            className="mt-2 btn btn-imperio"
            onClick={() => {
              navigator.clipboard.writeText(pix.qr_code_text);
              toastr.info(' ', 'Código PIX Copiado');
            }}
          >
            COPIAR CÓDIGO PIX
          </button>
        </>
      );
    }
    if (!pix.is_promoter && isImperioPaid) {
      return (
        <>
          <QRCode
            value={pix.qr_code_text}
            style={{ filter: `blur(10px)` }}
            size={200}
          />

          <strong className="mt-4">Primeiro pagamento realizado!</strong>
        </>
      );
    }
    return <></>;
  }

  return (
    <div className="container page__confirm">
      <div className="row">
        <div className="col-12">
          <div className="header">
            <i className="fas fa-check-circle" />
            <h3>O seu pedido foi finalizado com sucesso.</h3>
            <p>
              Agradecemos a sua preferência! Abaixo, você pode ver o número
              <br />
              do seu pedido e resumo da compra. Todos os dados serão enviados
              <br />
              no e-mail cadastrado. Aguarde a confirmação do pagamento.
            </p>
            <strong>Número do pedido:</strong>
            <span>{String(order.id).padStart(7, '0')}</span>
          </div>
          <div className="resume">
            <small>Resumo do pedido</small>
            <div className="resume__products">
              {order.orderProducts &&
                order.orderProducts.map(e => {
                  return (
                    <div className="resume__product" key={e.sku}>
                      <figure>
                        <img
                          src={e.product.photos[0].path}
                          alt={e.product.name}
                        />
                      </figure>
                      <div>
                        <p>{e.product.name}</p>
                        <p>R$ {normalizeCurrency(String(e.price))}</p>
                        <span>
                          SKU: {e.sku} | Quantidade: {e.qtd} unidade
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="resume__payment">
              <div>
                <p>Tipo de pagamento:</p>
                <strong>
                  {order.orderPayment && //eslint-disable-line
                  order.orderPayment[0].form_payment_id === 1 //eslint-disable-line
                    ? 'Boleto Bancário' //eslint-disable-line
                    : order.orderPayment &&
                      order.orderPayment[0].form_payment_id === 5 //eslint-disable-line
                    ? 'PIX' //eslint-disable-line
                    : 'Cartão de crédito'}{' '}
                </strong>
              </div>
              <div>
                <p>Subtotal:</p>
                <strong>R$ {normalizeCurrency(String(order.subtotal))}</strong>
              </div>
              {order.service > 0 && (
                <div>
                  <p>Serviços:</p>
                  <strong>R$ {normalizeCurrency(String(order.service))}</strong>
                </div>
              )}
              {order.discount > 0 && (
                <div>
                  <p>Desconto:</p>
                  <strong>
                    - R$ {normalizeCurrency(String(order.discount))}
                  </strong>
                </div>
              )}
              <div>
                <p>Valor do frete:</p>
                <strong>R$ {normalizeCurrency(String(order.frete))}</strong>
              </div>
              {order.orderPayment &&
                order.orderPayment[0].form_payment_id === 2 && (
                  <div>
                    <p>Parcelas:</p>
                    <strong>
                      Em{' '}
                      {order.orderPayment && order.orderPayment[0].installments}{' '}
                      vez de R${' '}
                      {order.orderPayment && order.orderPayment[0].total
                        ? normalizeCurrency(
                            String(
                              Math.round(
                                order.orderPayment[0].total /
                                  order.orderPayment[0].installments,
                              ),
                            ),
                          )
                        : ''}
                    </strong>
                  </div>
                )}
              <div>
                <p>Total:</p>
                <strong>R$ {normalizeCurrency(String(order.total))}</strong>
              </div>
            </div>
            <div>
              {linkBoleto !== '' && (
                <a
                  href={linkBoleto}
                  rel="noreferrer"
                  target="_blank"
                  className="btn btn-orange mr-3"
                >
                  Imprimir boleto
                </a>
              )}

              {pixPayments.length === 1 && (
                <div className="mt-4 mb-4 pix_payment">
                  <div>
                    <strong>Este QR code expira em 60 minutos</strong>
                    <p>Ao realizar o pagamento você será informado via email</p>
                  </div>
                  {pixPayments.map(pix => (
                    <div
                      key={pix.id}
                      className="mt-4 align-items-center d-flex flex-column"
                    >
                      <QRCode value={pix.qr_code_text} size={200} />
                      <button
                        type="button"
                        className="mt-4 btn btn-imperio"
                        onClick={() => {
                          navigator.clipboard.writeText(pix.qr_code_text);
                          toastr.info(' ', 'Código PIX Copiado');
                        }}
                      >
                        COPIAR CÓDIGO PIX
                      </button>
                    </div>
                  ))}

                  <p className="mt-4">
                    Escaneie o QR Code ou cópie o Código PIX.
                    <br />
                    Este é um <strong>PIX copia e cola</strong>. No seu App ou
                    Internet Banking procure por esta opção para colar o texto
                    copiado.
                  </p>
                </div>
              )}
              {pixPayments.length === 2 && (
                <div className="pix_payment">
                  {(!isImperioPaid || !isPromoterPaid) && (
                    <div>
                      <strong>
                        Atenção! Para este pedido é necessário realizar o
                        pagamento em duas transações
                      </strong>
                      <br />
                      <strong>Estes QR codes expiram em 60 minutos</strong>
                      <p>
                        Após realizado o primeiro pagamento o segundo QR Code
                        será liberado.
                      </p>
                    </div>
                  )}
                  {pixPayments.map(pix => (
                    <div
                      key={pix.id}
                      className="mt-4 justify-content-center d-flex"
                    >
                      <div className="d-flex flex-column align-items-center">
                        {handlePixFlow(pix)}
                      </div>
                    </div>
                  ))}
                  {(!isImperioPaid || !isPromoterPaid) && (
                    <p className="mt-4">
                      Escaneie o QR Code ou cópie o Código PIX.
                      <br />
                      Este é um <strong>PIX copia e cola</strong>. No seu App ou
                      Internet Banking procure por esta opção para colar o texto
                      copiado.
                    </p>
                  )}
                </div>
              )}

              <a
                href="https://homolog.lojasimperio.tupi.io/"
                className="mt-4 btn btn-imperio"
              >
                Voltar para página inicial
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
