import styled from 'styled-components';

export const Container = styled.div``;

export const ResumeContainer = styled.div`
  transition: 50ms;
  @media screen and (max-width: 767px) {
    transform: translateY(0) !important;
  }
`;
