import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Header from '~/components/Header';
import Footer from '~/components/Footer';

import Autenticate from './Autenticate';
import Service from './Service';
import Checkout from './Checkout';
import Confirm from './Confirm';

import { Container, Operative } from './styles';

const Main: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Operative>
          <Switch>
            <Route exact path="/garantia-estendida" component={Service} />
            <Route exact path="/checkout/:order_id" component={Checkout} />
            <Route
              exact
              path="/autenticate/:order_id/:token"
              component={Autenticate}
            />
            <Route
              exact
              path="/detalhamento-do-pedido/:id"
              component={Confirm}
            />
          </Switch>
        </Operative>
      </Container>
      <Footer />
    </>
  );
};

export default Main;
